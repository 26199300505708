//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Toast } from "vant";
export default {
  name: "Home",
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      leaderSearch: "",
      listType: 0,
      typeData: [],
    };
  },
  created() {
    this.listType = this.$route.query.id ? this.$route.query.id : 0;
    this.typeData = [
      {
        title: "待处理",
        ajaxData: {
          url: "/boogoo/listTaskPageV2",
          param: {
            pathVariableMap: {
              organizationId: "0",
            },
            headerParamMap: {},
            requestParamMap: {
              employeeCode: this.$memberInfo.hhrEmpid, //当前登录人员ID必填
            },
            payload:
              '{"page":0,"size":10,"createdAfter":null,"createdBefore":null,"maximumPriority":null,"minimumPriority":null}',
          },
        },
      },
      {
        title: "已处理",
        ajaxData: {
          url: "/boogoo/listProcessInstForPageV2",
          param: {
            pathVariableMap: {
              organizationId: "0",
            },
            headerParamMap: {},
            requestParamMap: {
              involved: true,
              employeeCode: this.$memberInfo.hhrEmpid,
            },
            payload: '{"page":0,"size":10}',
          },
        },
        type: "involved",
      },
      {
        title: "已发起",
        ajaxData: {
          url: "/boogoo/listProcessInstForPageV2",
          param: {
            pathVariableMap: {
              organizationId: "0",
            },
            headerParamMap: {},
            requestParamMap: {
              startedBy: true,
              employeeCode: this.$memberInfo.hhrEmpid,
            },
            payload: '{"page":0,"size":10}',
          },
        },
        type: "startedBy",
      },
      {
        title: "我收到的",
        ajaxData: {
          url: "/boogoo/listProcessInstForPageV2",
          param: {
            pathVariableMap: {
              organizationId: "0",
            },
            headerParamMap: {},
            requestParamMap: {
              carbonCopy: true,
              employeeCode: this.$memberInfo.hhrEmpid,
            },
            payload: '{"page":0,"size":10}',
          },
        },
        type: "carbonCopy",
      },
    ];
    this.fetchListData();
  },
  mounted() {
    window.document.title = this.typeData[this.listType].title;
  },
  activated() {
    window.document.title = this.typeData[this.listType].title;
  },
  methods: {
    fetchListData() {
       this.loading=true;
      
      let postData = this.typeData[this.listType].ajaxData.param;
      this.axios
        .post(this.typeData[this.listType].ajaxData.url, postData)
        .then((res) => {
           this.loading=false;
      
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              if (resultData.content.length > 0) {
                let resArr = this.list.concat(resultData.content);
                this.list = resArr;
                this.adddPage(resultData.number);
              } else {
                this.finished = true;
              }
            }
          }
        })
        .catch(function () {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    adddPage(num) {
      let sta = JSON.parse(this.typeData[this.listType].ajaxData.param.payload);
      sta.page = num + 1;
      this.typeData[this.listType].ajaxData.param.payload = JSON.stringify(sta);
      console.log(this.typeData[this.listType].ajaxData.param.payload);
    },
    goNext(item) {
      console.log(item);
      switch (item.processName) {
        case "员工调动流程":
          this.$router.push(
            "/oaplatform/detail/hrgprocess?id=" +
              item.id +
              "&type=" +
              this.typeData[this.listType].type
          );
          break;
        case "用人申请流程":
          this.$router.push(
            "/oaplatform/detail/yrgprocess?id=" +
              item.id +
              "&type=" +
              this.typeData[this.listType].type
          );
          break;
        case "员工试用期转正NEW":
          this.$router.push(
            "/oaplatform/detail/fkinprocess?id=" +
              item.id +
              "&type=" +
              this.typeData[this.listType].type
          );
          break;
        case "员工离职流程":
          this.$router.push(
            "/oaplatform/detail/fkoffprocess?id=" +
              item.id +
              "&type=" +
              this.typeData[this.listType].type
          );
          break;

        default:
          this.$router.push(
            "/oaplatform/detail/hprocess?id=" +
              item.id +
              "&type=" +
              this.typeData[this.listType].type
          );
      }
    },
    onLoad() {
      this.loading = false;

      // 数据全部加载完成
      if (this.list.length >= 40) {
        this.finished = true;
      }
    },
  },
};
